<template>
  <div>
    <v-breadcrumbs
      class="pt-3 pl-7"
      :items="[{ text: 'Attributes', exact: true,  to: { name: 'attributes' } }, { text: oldAttribute.name }]"
    >
    </v-breadcrumbs>
    <v-row
      class="mt-0"
    >
      <v-col
        cols="12"
        md="5"
      >

        <v-card
          flat
          class="mx-3 pb-9"
        >
          <v-toolbar
            dark
            class="tertiary"
            dense
            elevation="0"
          >
            <v-toolbar-title>Attribute</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="editAttributeClick()"
              color="secondary"
              :disabled="!user.can('attribute.update')"
            >
              <v-icon small>fas fa-edit</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>

            <dl class="row table mt-5">

              <dt class="col-5">Name:</dt>
              <dd class="col-7">{{ oldAttribute.name }}</dd>
            </dl>

          </v-card-text>

          <attribute-dialog
            v-model="attributeDialog"
            :attributes="attribute"
            :errors="attributeErrors"
            @save="saveAttributeClick()"
          ></attribute-dialog>

        </v-card>

      </v-col>

      <v-col
        cols="12"
        md="7"
      >
        <v-card
          flat
          class="mx-3 pb-9"
        >

          <v-toolbar
            dark
            class="tertiary"
            dense
            elevation="0"
          >
            <v-toolbar-title>Values</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              dense
              background-color="primary"
              v-model="filter.query"
              label="Search..."
              single-line
              hide-details
              solo
              @keyup="searchKeyUp()"
            ></v-text-field>
            <v-btn
              icon
              color="secondary"
              @click="newValueClick()"
              :disabled="!user.can('attribute-value.create')"
            >
              <v-icon
                small
              >fas fa-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <v-data-table
            :class="{ 'mt-5': true, 'clickable': user.can('attribute-value.update') }"
            :headers="valueHeaders"
            :items="values"
            :loading="isSearching"
            :options.sync="options"
            :server-items-length="totalCount"
            :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
            @click:row="rowValueClick"
          >
          </v-data-table>

          <attribute-value-dialog
            v-model="valueDialog"
            :attributes="value"
            :errors="valueErrors"
            :attribute-items="attributes"
            @save="saveValueClick()"
          ></attribute-value-dialog>

        </v-card>
      </v-col>
    </v-row>


  </div>
</template>

<script>
import { inject, ref, watch  } from 'vue'
import AttributeDialog from '@/components/AttributeDialog'
import AttributeValueDialog from '@/components/AttributeValueDialog'
import { useRouter, useRoute } from 'vue-router/composables'


export default {
  components: {
    AttributeDialog,
    AttributeValueDialog
  },
  setup() {
    const api = inject('api')
    const { user } = api
    const router = useRouter()
    const route = useRoute()

    const attributeModel = api.model('attributes/:id', {
      params: {
      },
    })

    const { attributes: attribute, errors: attributeErrors, oldAttributes: oldAttribute } = attributeModel
    const attributeDialog = ref(false)

    const attributeCollection = api.collection('attributes')
    
    const { items: attributes } = attributeCollection.all()

    function saveAttributeClick() {
      attributeModel.save().then(() => {
        attributeCollection.all()
        attributeDialog.value = false
      })
    }

    const valueCollection = api.collection('attribute-values', {
      params: {
      },
      filter: {
        attribute_id: 'eq',
        query: 'query:code,name',
      },
    })

    const { items: values, filter, options, totalCount, isSearching } = valueCollection

    const valueHeaders = [
      { text: 'Code', value: 'code', },
      { text: 'Name', value: 'name' },
    ]

    const searchKeyUp = valueCollection.debounceSearch(300)

    const valueModel = api.model('attribute-values/:id')

    const { attributes: value, errors: valueErrors } = valueModel
    const valueDialog = ref(false)

    function newValueClick() {
      valueModel.clear().setAttribute('attribute_id', attribute.id)
      valueDialog.value = true
    }

    function saveValueClick() {
      valueModel.save().then(() => {
        valueCollection.search()
        valueDialog.value = false
      })
    }

    function rowValueClick(item) {
      if (user.can('attribute-value.update')) {
        valueModel.clear().populate(item)
        valueDialog.value = true
      }
    }

    function editAttributeClick() {
      attributeModel.reset()
      attributeDialog.value = true      
    }


    watch(() => route, ({ params }) => {
      if (params.id) {
        attributeModel.find(params.id).catch(() => {
          router.push({
            name: 'attributes',
          })
        })
        valueCollection.setFilter('attribute_id', params.id).search()
      }
    }, { immediate: true })

    return {
      user,
      attribute,
      attributeErrors,
      oldAttribute,
      attributeDialog,
      editAttributeClick,
      saveAttributeClick,
      values,
      filter,
      options,
      totalCount,
      isSearching,
      valueHeaders,
      value,
      valueErrors,
      valueDialog,
      newValueClick,
      saveValueClick,
      rowValueClick,
      attributes,
      searchKeyUp,
    }

  },
}
</script>

<style scoped>

:deep(.v-data-table.clickable table tbody tr:not(.v-data-table__empty-wrapper):hover) {
  cursor: pointer;
}

dl.table dt, dl.table dd {
  padding-top: 2px;
  padding-bottom: 2px;
}


</style>